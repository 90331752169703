.app-header {
    margin: 1em 0;
}

.card {
    background: rgba(255, 255, 255, 0.9);
}

.node-tree {
    cursor: pointer;
}

.node-tree:hover {
    background-color: $blue !important;
    color: white;
}

#header-sidebar-area,
.header-icons {
    img {
        height: 30px;
        cursor: pointer;
        margin: 0 10px;        
    }

    svg{
        font-size: 2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        cursor: pointer;
    }
}

#logoUD {
    height: 35px;
}

#header-sidebar-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.alternate-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    .form-group {
        margin: 0;
    }

    input[type="checkbox"] {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }

}

.react-player {
    height: 1px !important;
    width: 1px !important;
}

#footer {
    margin-top: 2em;
    position: fixed;
    bottom: 0;
    width: 100%;

    .row{
        text-align: center;
    }

    .footer-text{
        align-self: center;
    }
    
    .button-speaker {
        padding: 10px 2em;
        cursor: pointer;

        img {
            height: 40px;
        }
        span {
            font-size: 1.4em;
            @include media-breakpoint-up(md) {
                font-size: 1.8em;
            }
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .current-speaker {
        background: $blue;
        color: white;
    }
}

 
.card-mini {
    @include media-breakpoint-up(md) {
        width: 500px;
    }
}
.card-standard {
    width: 100%;
    margin-bottom: 3em;
    @include media-breakpoint-up(md) {
        width: 700px;
    }
}

.card-standard.transparent {
    background: none !important;
    border: none;

    .node-tree {
        background:rgba(255, 255, 255, 0.9);
        margin-bottom: 1em !important;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
    }

    .card-title {
        background:rgba(255, 255, 255, 0.9);
        padding: 1em;
        text-transform: uppercase;
    }

    .card-text {
        padding-top: 1em;
        padding-bottom: 1em;
        background:rgba(255, 255, 255, 0.9);
        .node-tree {
            text-transform: none;
            background: none;
            .col {
                .row:last-child {
                    font-weight: normal;
                }
            }
        }
    }
}

.hide {
    display: none !important;
}