// Google Font
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

// Override default variables before the import
$font-family-sans-serif: 'Fira Sans', sans-serif;
$blue: #167bff;
$body-bg: $blue;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';